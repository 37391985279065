import request from "@/utils/request";
export function getClassList(params) {
  return request({
    url: "/api/School/ClassList",
    data: { filterData: { parentID: 0 }, ...params },
    method: "post",
    header: {
      "Content-Type": "application/json;charset=utf-8", //如果写成contentType会报错
    },
  });
}
export function getClassWithChildren(params) {
  return request({
    url: "/api/School/ClassWithChildrenList",
    data: { ...params },
    method: "post",
    header: {
      "Content-Type": "application/json;charset=utf-8", //如果写成contentType会报错
    },
  });
}

export function getCourseList(params) {
  return request({
    url: "/api/School/CourseList",
    data: { ...params },
    method: "post",
    header: {
      "Content-Type": "application/json;charset=utf-8", //如果写成contentType会报错
    },
  });
}

// 获取详情
export function getCourseInfo(id) {
  return request({
    url: `/api/School/Course?para=${id}`,
  });
}

// 获取视频观看记录 /api/School/GetBrowsingTimebyUser
export function getBrowsingTimebyUser() {
  return request({
    url: "/api/School/GetBrowsingTimebyUser",
  });
}

// 学习时长排行
export const learnRankListAPI = (obj) => {
  return request.post("/api/School/LearnRankList", obj);
};
// 讲师的列表
export const getSchoolTeacherAPI = (obj) => {
  return request.post("/api/Teacher/List", obj);
};


//获取合集信息
export function GetCourseCollectionsShow(id) {
  return request({
    url: `/api/School/GetCourseCollectionsShow?id=${id}`,
  });
}