<template>
  <div class="container" ref="wrapper">
    <!-- <div class="containerlist"> -->
    <van-nav-bar :title="title" left-arrow @click-left="$router.back()">
    </van-nav-bar>
    <div class="learn">
      <van-search
        shape="round"
        v-model="obj.FilterData.userName"
        placeholder="请输入搜索关键词"
        @search="onSearh1"
        ref="searchRef"
      />
      <van-swipe :autoplay="3000" style="height:150px;width:100%" v-if="images.length>0">
        <van-swipe-item v-for="(item, index) in images" :key="index">
          <img
            :src="item.imageUrl"
            style="width:100%;height:100%"
            @click="$router.push(item.urlPath)"
          />
        </van-swipe-item>
      </van-swipe>
      <van-row type="flex" style="margin-top:3px;margin-bottom:3px">
        <van-col span="3" style="margin-right:2px;margin-left:5px"
          ><van-tag
            round
            :color="obj.SorterType == 0 ? '#9d1d22' : ''"
            size="large"
            @click="onSearh(0)"
            >默认</van-tag
          ></van-col
        >
        <van-col span="5"
          ><van-tag
            round
            :color="obj.SorterType == 1 ? '#9d1d22' : ''"
            size="large"
            @click="onSearh(1)"
            >最近上课</van-tag
          ></van-col
        >
        <van-col span="5"
          ><van-tag
            round
            :color="obj.SorterType == 2 ? '#9d1d22' : ''"
            size="large"
            @click="onSearh(2)"
            >上课次数</van-tag
          ></van-col
        >
        <van-col span="5"
          ><van-tag
            round
            :color="obj.SorterType == 3 ? '#9d1d22' : ''"
            size="large"
            @click="onSearh(3)"
            >上课时间</van-tag
          ></van-col
        >
      </van-row>
      <!-- <van-tag round type="primary">标签</van-tag>
      <van-tag round type="primary">标签</van-tag>
      <van-tag round type="primary">标签</van-tag> -->
    </div>
    <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh"> -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
    <van-cell-group>
          <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->

          <van-cell
             v-for="item in list"
        :key="item.id"
        @click="toclick(item.id)"
          >
      <van-row
       
      >
        <van-col span="8" class="leftimg">
        <van-image
                lazy-load
                class="teacherimg"
                fit="contain"
                style="width: 110px; height: 150px"
                :src="item.imageUrl"
              />
            
        </van-col>
        <van-col span="16" class="right"
          ><h3>{{ item.userName }}</h3>
          <br />
          <p v-if="item.grade">讲师职级：{{ item.grade }}<br /><br /></p>

          课程数量：{{ item.courseCount }}
          <br />
          <br />
          讲课时长：{{ item.courseTime }}小时
          <br />
          <br />
          <!-- 讲师介绍：
          <span v-text="item.detailsHtml"></span> -->
        </van-col>
      </van-row>
          </van-cell>
    </van-cell-group>
    </van-list>
    <!-- </van-pull-refresh> -->
    <!-- </div> -->
  </div>
</template>

<script>
import { getSchoolTeacherAPI } from "../../api/school";
import { getSchoolListAPI } from "../../api/seting";
// import courseTeachersList from "./components/courseTeachersList.vue";
export default {
  components: {},
  data() {
    return {
      scroll:0,
      title: "讲师资源",
      active: "",
      obj: {
        fromType: 1,
        current: 1,
        pageSize: 5,
        FilterData: {
          userName: "",
        },

        SorterType: 0,
      },
      body: {
        fromType: 1,
        current: 1,
        pageSize: 100,
        FilterData: {
          setTypes: [208],
          // label: "讲师 轮播图",
        },
        // value: 208,
      },
      list: [],
      loading: false,
      finished: false,
      images: [],
    };
  },
  created() {
    this.initswiper();
    // this.onSearh();
  },
  
  activated() {
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  watch: {},
  methods: {
    getScroll(event) {
      // let scrollBottom =
      //   event.target.scrollHeight -
      //   event.target.scrollTop -
      //   event.target.clientHeight;
      // console.log(event.target.scrollTop);
      if (event.target.scrollTop > 100) {
        console.log(this.$refs.tabsRef);
      }
    },
    async initswiper() {
      let result = await getSchoolListAPI(this.body);
      // console.log(result);
      let { data } = result.data;
      console.log(data);
      this.images = data;
    },

    onSearh1() {
      this.list = [];
      this.obj.current = 1;
      this.obj.pageSize = 5;
      this.onLoad();
    },

    onSearh(typeid) {
      this.obj.SorterType = typeid;
      this.list = [];
      this.obj.current = 1;
      this.obj.pageSize = 5;
      this.onLoad();

      // this.obj.SorterType = typeid;
      //   this.obj.FilterData.TrueName = "";
    },
    //     async replay(typeid) {
    // this.obj.SorterType = typeid;

    //     },
    async onLoad() {
      this.loading = true;
      // this.obj.SorterType = this.active;
      // this.obj.SorterType = typeid;
      // this.list = [];
      let result = await getSchoolTeacherAPI({ ...this.obj });
      let { data } = result.data;

      function cutZero(old) {
        //拷贝一份 返回去掉零的新串
        old = old + "";
        var newstr = old; //循环变量 小数部分长度
        var leng = old.length - old.indexOf(".") - 1; //判断是否有效数
        if (old.indexOf(".") > -1) {
          //循环小数部分
          for (let i = leng; i > 0; i--) {
            //如果newstr末尾有0
            if (
              newstr.lastIndexOf("0") > -1 &&
              newstr.substr(newstr.length - 1, 1) == 0
            ) {
              var k = newstr.lastIndexOf("0"); //如果小数点后只有一个0 去掉小数点
              if (newstr.charAt(k - 1) == ".") {
                return newstr.substring(0, k - 1);
              } else {
                //否则 去掉一个0
                newstr = newstr.substring(0, k);
              }
            } else {
              //如果末尾没有0
              return newstr;
            }
          }
        }
        return old;
      }
      for (let i = 0; i < data.length; i++) {
        data[i].courseTime = cutZero(data[i].courseTime);
      }
      this.list.push(...data);
      this.loading = false;

      if (this.obj.current * this.obj.pageSize < result.data.total) {
        this.obj.current++;
        this.finished = false;
      } else {
        this.finished = true;
      }
      // this.obj.FilterData.userName = "";
    },

    toclick(id) {

      let wrapperScrollTop = this.$refs.wrapper.scrollTop;
      //存储 scrollTop 的值
      this.scroll = wrapperScrollTop;

      this.$router.push({
        path: "/course/list",
        query: { tid: id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  // padding-bottom: 45px;
  box-sizing: border-box;
  // /deep/ .van-list {
  //   height: 100%;
  // }
  /deep/ .van-tabs {
    height: 100%;
  }
  /deep/.van-tabs__content {
    height: 100%;
  }
}
.van-tab__pane {
  height: 100%;
}
// .containerlist {
//   height: 100%;
// }
.loading {
  text-align: center;
}
.leftimg {
  padding-top: 5px;
  padding-left: 5px;
}
.right {
  padding-top: 10px;
}
.teacherimg {
  height: 150px;
}
</style>
