import request from "@/utils/request";

export function getSetingList(params) {
  return request({
    url: "/api/Seting/List",
    data: { ...params },
    method: "post",
    header: {
      "Content-Type": "application/json;charset=utf-8", //如果写成contentType会报错
    },
  });
}

//
export function GetWorkDay() {
  return request({
    url: "/api/Seting/GetDay",
  });
}

//
export function GetShareEndeavorImage() {
  return request({
    url: "/api/Seting/GetShareEndeavorImage",
  });
}

export function updateReadCount(id) {
  return request({
    url: "/api/Seting/UpdateReadCount?id=" + id,
    data: {},
    method: "post",
    header: {
      "Content-Type": "application/json;charset=utf-8", //如果写成contentType会报错
    },
  });
}

// 中酿学院首页
export const getSchoolListAPI = (obj) => {
  return request.post("/api/Seting/ListForSchool",  obj );
};

// 配置顺序
export const getSetingBase = (obj) => {
  return request.post("/api/Seting/ListSetingBase",  obj );
};
